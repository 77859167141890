@font-face {
  font-family: 'showcard-gothic-regular';
  src: url('/public/SHOWG.TTF') format('woff'), url('/public/SHOWG.TTF') format('opentype'),
    url('/public/SHOWG.TTF') format('truetype');
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background: #f2f5f8;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  padding: 0;
  position: relative;
  z-index: 1;
}

#root {
  width: 100%;
  min-height: 100vh;
  max-width: 100%;
}

.apexcharts-legend-marker {
  margin-left: 5px;
  margin-right: 0;
}

.apexcharts-tooltip-marker {
  margin-left: 10px;
  margin-right: 0 !important;
}

.apexcharts-tooltip-series-group {
  justify-content: right !important;
}

.apexcharts-tooltip-text-y-value {
  font-weight: 400 !important;
  margin-left: 0 !important;
  margin-right: 5px !important;
}

.apexcharts-tooltip-text-y-label {
  font-weight: 400 !important;
}

.apexchartsdecks .apexcharts-tooltip.apexcharts-active {
  opacity: 0.9 !important;
}

@media print {

  html,
  body {
    display: none;
  }
}

.introjs-tooltipReferenceLayer * {
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.introjs-skipbutton {
  margin-left: 0 !important;
  margin-right: 5px !important;
}

*.introjs-showElement {
  z-index: unset !important;
}

.introjs-tooltip-header {
  padding-left: 10px !important;
}

.introjs-tooltip {
  max-width: 450px;
  min-width: 340px;
}

.introjs-bullets ul li a {
  background: #24cd99 !important;
}

.introjs-button {
  background: #24cd99 !important;
  border-color: #24cd99 !important;
  border-radius: 8px !important;
  text-shadow: none !important;
  color: #fff !important;
}

.introjs-button.introjs-disabled {
  opacity: 0.7;
}

.introjs-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(36, 205, 153, 0.35);
}

.introjs-tooltip {
  border-radius: 8px !important;
  color: #475569;
}

.introjs-skipbutton {
  color: #475569 !important;
}

.ck-balloon-panel {
  z-index: 999999999 !important;
}

.ck.ck-content.ck-editor__editable {
  border: 1px solid #ccc;
}

.ck.ck-content.ck-editor__editable p {
  margin: 8px 0;
}

.comment-container img {
  max-width: 100%;
}